$(function() {

  $('#racing-form').validate({
    rules: {
      email: {
        required: true,
        email: true
      },
      messaggio: {
        required: true
      }
    },
    messages: {
      email: {
        required: "Inserisci un indirizzo email"
      },
      messaggio: {
        required: "Scrivi un messaggio"
      }
    },
    submitHandler: function() {

      if (grecaptcha.getResponse() == ''){
        $('#reCaptcha-error').show();
      } else {
        $('reCaptcha-error').hide();
        var data = {
          email: $('#email').val(),
          messaggio: $('#messaggio').val()
        }
        $.ajax({
          type:"POST",
          data: data,
          url:"sendMail.php",
          success: function() {
            $('#racing-form :input').attr('disabled', 'disabled');
            $('#racing-form').fadeTo( "slow", 0.15, function() {
              $(this).find(':input').attr('disabled', 'disabled');
              $(this).find('label').css('cursor','default');
              $('#success').fadeIn();
            });
          },
          error: function() {
            $('#racing-form').fadeTo( "slow", 0.15, function() {
              $('#error').fadeIn();
            });
          }
        });
      }



    }
  });
});
